import { indexData } from "@/api/api";
export default {
  name: "index",
  data() {
    return {
      indexInfo: []
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    getList() {
      let user = JSON.parse(localStorage.getItem('user'));
      indexData(user.user_type).then(res => {
        this.indexInfo = res.data;
      });
    }
  }
};